import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, Box, Typography, IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, OutlinedInput, Chip } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../services/api';
import './style.css';

const CreatePropertyForm = ({ open, onClose, onCreate, property }: any) => {
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: property || {}
    });
    const [sections, setSections]:any = useState([]);
    const [selectedSections, setSelectedSections] = useState<any>({});
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imagesToRemove, setImagesToRemove] = useState<number[]>([]);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (property) {
            reset(property);
            setImagePreviews(property.images.map((img: any) => img.path));
        }

        const fetchSections = async () => {
            try {
                const response = await api.get('list-sections');
                setSections(response.data);
                if (property && property.section_ids) {
                    const sectionIdsMap = property.section_ids.reduce((acc: any, id: number) => {
                        acc[id] = true;
                        return acc;
                    }, {});
                    setSelectedSections(sectionIdsMap);
                }
            } catch (error) {
                console.error('Failed to fetch sections', error);
            }
        };

        fetchSections();
    }, [property, reset]);

    const onSubmit = async (data: any) => {
        const formData = new FormData();

        // Adiciona campos do formulário ao FormData
        for (const key in data) {
            formData.append(key, data[key]);
        }

        // Adiciona seções selecionadas ao FormData
        Object.keys(selectedSections).forEach(id => {
            if (selectedSections[id]) {
                formData.append('section_ids[]', id);
            }
        });

        // Adiciona imagens selecionadas ao FormData
        selectedFiles.forEach(file => {
            formData.append('images', file);
        });

        // Adiciona imagens a serem removidas ao FormData
        imagesToRemove.forEach(imgId => {
            formData.append('imagesToRemove', imgId.toString());
        });

        // Chama a função onCreate com o FormData
        onCreate(formData);

        // Reseta o formulário e o estado de imagens
        reset();
        setSelectedFiles([]);
        setImagePreviews([]);
        setImagesToRemove([]);
        setShowAlert(false);
        onClose();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = Array.from(files);
            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
            const previews = newFiles.map(file => URL.createObjectURL(file));
            setImagePreviews(prevPreviews => [...prevPreviews, ...previews]);
        }
    };

    const handleRemoveImage = (index: number, existingImage: boolean) => {
        if (existingImage && property && property.images[index]) {
            const imgId = property.images[index].id;
            setImagesToRemove(prev => {
                if (!prev.includes(imgId)) {
                    return [...prev, imgId];
                }
                return prev;
            });
        }
        setImagePreviews(imagePreviews.filter((_, i) => i !== index));
        if (!existingImage) {
            setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
        }
    };

    const handleSelectSection = (id: number) => {
        setSelectedSections((prev:any) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{property ? 'Editar Imóvel' : 'Criar Novo Imóvel'}</DialogTitle>
            <DialogContent>
                {showAlert && <Alert severity="error">Por favor, preencha todos os campos obrigatórios.</Alert>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="friendly_title"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Título <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.friendly_title}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => <TextField {...field} label="Descrição" variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="property_type"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Tipo de Imóvel <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.property_type}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="state"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Estado <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.state}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="state_abbreviation"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Abreviação do Estado <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.state_abbreviation}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Cidade <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.city}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="neighborhood"
                                control={control}
                                render={({ field }) => <TextField {...field} label="Bairro" variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="sale_price"
                                control={control}
                                render={({ field }) => <TextField {...field} label="Preço de Venda" variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="area"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Área <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.area}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="bedrooms"
                                control={control}
                                render={({ field }) => <TextField {...field} label="Quartos" variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="suites"
                                control={control}
                                render={({ field }) => <TextField {...field} label="Suítes" variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="parking_spots"
                                control={control}
                                render={({ field }) => <TextField {...field} label="Vagas de Estacionamento" variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="section_ids-label">Seções</InputLabel>
                                <Select
                                    labelId="section_ids-label"
                                    label="Seções"
                                    multiple
                                    value={Object.keys(selectedSections).filter(id => selectedSections[id])}
                                    input={<OutlinedInput label="Seções" />}
                                    renderValue={(selected: any) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value: any) => (
                                                <Chip key={value} label={sections.find((section: any) => section.id === parseInt(value))?.name} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {sections.map((section: any) => (
                                        <MenuItem key={section.id} value={section.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!selectedSections[section.id]}
                                                        onChange={() => handleSelectSection(section.id)}
                                                    />
                                                }
                                                label={section.name}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                style={{ marginTop: 16 }}
                            />
                            <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                                {imagePreviews.map((src, index) => (
                                    <Box key={index} position="relative">
                                        <img src={src} alt={`preview ${index}`} width={100} height={100} style={{ objectFit: 'cover' }} />
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => handleRemoveImage(index, property && index < property.images.length)}
                                            className='remove-img'
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">Cancelar</Button>
                        <Button type="submit" color="primary" variant="contained">{property ? 'Atualizar' : 'Criar'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreatePropertyForm;