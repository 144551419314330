import React, { useState } from 'react';
import Main from "../../../components/Main";
import OfficesTable from '../../../sections/Admin/Offices/Table';

const Offices = () => {

  return (
    <Main>
        <OfficesTable/>
    </Main>
  );
};

export default Offices;
