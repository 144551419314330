import React, { useState } from 'react';
import Main from "../../../components/Main";
import PropertiesTable from '../../../sections/Admin/MyProperties/Table';

const MyProperties = () => {

  return (
    <Main>
        <PropertiesTable/>
    </Main>
  );
};

export default MyProperties;
