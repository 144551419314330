import React, { useState } from 'react';
import Carousel from '../../../sections/Properties/Carousel';
import Filter from '../../../components/Filter';
import Main from "../../../components/Main";

const AdminHome = () => {
  const [finalType, setFinalType] = useState(null);
  const [operationType, setOperationType] = useState(2);
  const [propertyTypes, setPropertyTypes] = useState(null);
  const [objectFilter, setObjectFilter] = useState(null);

  const handleFilterChange = (newOperationType: any, newPropertyFinalTypes:any, newPropertyTypes: any, newObjectFilter: any) => {
    setFinalType(newPropertyFinalTypes);
    setOperationType(newOperationType);
    setPropertyTypes(newPropertyTypes);
    setObjectFilter(newObjectFilter)
  };

  return (
    <Main>
      <Filter  onFilterChange={handleFilterChange} />
      <Carousel title="" subTitle="" operationType={operationType} propertyTypes={propertyTypes} finalType={finalType} objectFilter={objectFilter} />
    </Main>
  );
};

export default AdminHome;
