import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography, SelectChangeEvent, Grid } from '@mui/material';
import FilterSelect from '../FilterSelect';

const propertyOptions = [
    {
        "value": "COMERCIAL",
        "label": "COMERCIAL",
        "children": [
            {
                "value": "ANDAR / LAJE CORPORATIVA",
                "label": "ANDAR / LAJE CORPORATIVA"
            },
            {
                "value": "CASA COMERCIAL",
                "label": "CASA COMERCIAL"
            },
            {
                "value": "EDIFICIO",
                "label": "EDIFICIO"
            },
            {
                "value": "GALPÃO",
                "label": "GALPÃO"
            },
            {
                "value": "GARAGEM",
                "label": "GARAGEM"
            },
            {
                "value": "LOJA",
                "label": "LOJA"
            },
            {
                "value": "PRÉDIO ",
                "label": "PRÉDIO "
            },
            {
                "value": "SALA COMERCIAL",
                "label": "SALA COMERCIAL"
            },
            {
                "value": "TERRENO ",
                "label": "TERRENO "
            },
            {
                "value": "TERRENO COMERCIAL",
                "label": "TERRENO COMERCIAL"
            }
        ]
    },
    {
        "value": "RESIDENCIAL",
        "label": "RESIDENCIAL",
        "children": [
            {
                "value": "APARTAMENTO",
                "label": "APARTAMENTO"
            },
            {
                "value": "APARTAMENTO DUPLEX",
                "label": "APARTAMENTO DUPLEX"
            },
            {
                "value": "APARTAMENTO GARDEN",
                "label": "APARTAMENTO GARDEN"
            },
            {
                "value": "APARTAMENTO TRIPLEX",
                "label": "APARTAMENTO TRIPLEX"
            },
            {
                "value": "CASA",
                "label": "CASA"
            },
            {
                "value": "CASA CONDOMÍNIO",
                "label": "CASA CONDOMÍNIO"
            },
            {
                "value": "COBERTURA ",
                "label": "COBERTURA "
            },
            {
                "value": "COBERTURA DUPLEX",
                "label": "COBERTURA DUPLEX"
            },
            {
                "value": "FLAT",
                "label": "FLAT"
            },
            {
                "value": "KITNET / CONJUGADO",
                "label": "KITNET / CONJUGADO"
            },
            {
                "value": "LOFT / STUDIO",
                "label": "LOFT / STUDIO"
            },
            {
                "value": "SOBRADO",
                "label": "SOBRADO"
            },
            {
                "value": "SOBRADO EM CONDOMÍNIO",
                "label": "SOBRADO EM CONDOMÍNIO"
            },
            {
                "value": "TERRENO ",
                "label": "TERRENO "
            },
            {
                "value": "TERRENO CONDOMÍNIO",
                "label": "TERRENO CONDOMÍNIO"
            }
        ]
    },
    {
        "value": "RURAL",
        "label": "RURAL",
        "children": [
            {
                "value": "CHÁCARA",
                "label": "CHÁCARA"
            },
            {
                "value": "FAZENDA",
                "label": "FAZENDA"
            },
            {
                "value": "SÍTIO",
                "label": "SÍTIO"
            }
        ]
    }
]
const flattenOptions = propertyOptions.reduce((acc: any, category: any) => {
    acc.push({ value: category.value, label: category.label, isCategory: true });
    category.children.forEach((child: any) => {
        acc.push({ ...child, isCategory: false });
    });
    return acc;
}, []);

const Filter = ({ onFilterChange }: { onFilterChange: (transactionType: number, category: string, subCategory: string, objectFilter: any) => void }) => {
    const [transactionType, setTransactionType] = useState<number>(2);
    const [selection, setSelection] = useState<string>('');
    const [finalType, setFinalType] = useState<string>('');
    const [propertyTypes, setPropertyTypes] = useState<any>(null);
    const [objectFilter, setObjectFilter] = useState<any>([]);

    useEffect(() => {
        // Chamada de onFilterChange dentro de useEffect para garantir que seja chamado com os estados mais recentes
        onFilterChange(transactionType, finalType, propertyTypes, objectFilter);
    }, [transactionType, finalType, propertyTypes, objectFilter, onFilterChange]); // Adicione onFilterChange às dependências para reagir a mudanças

    const handleTransactionChange = (event: SelectChangeEvent<string>) => {
        setTransactionType(parseInt(event.target.value, 10));
    };

    const handleSelectionChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        const selectedOption = flattenOptions.find((option: any) => option.value === selectedValue);

        if (selectedOption && selectedOption.isCategory) {
            setFinalType(selectedValue);
            setPropertyTypes(null);
        } else {
            setPropertyTypes(selectedValue);
            const categoryOption = flattenOptions.find((option: any) => option.children?.some((child: any) => child.value === selectedValue));
            setFinalType(categoryOption?.value || '');
        }
        setSelection(selectedValue);
    };

    const handleSearchChange = (newObjectFilter: any) => {
        setObjectFilter(newObjectFilter);
    };
    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6">Filtrar Imóveis</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                        <InputLabel>Venda/Aluguel</InputLabel>
                        <Select
                            value={transactionType.toString()}
                            onChange={handleTransactionChange}
                        >
                            <MenuItem value="2">Venda</MenuItem>
                            <MenuItem value="1">Aluguel</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>

                    <FormControl fullWidth>
                        <InputLabel>Tipo de imóvel</InputLabel>
                        <Select
                            value={selection}
                            onChange={handleSelectionChange}
                        >
                            {flattenOptions.map((option: any) => (
                                <MenuItem key={option.value} value={option.value} style={{ fontWeight: option.isCategory ? 700 : 400 }}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FilterSelect onSearchChange={handleSearchChange} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filter;