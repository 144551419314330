import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';

interface OptionType {
  label: string;
  id: string;
  type: any;
}

interface StateFilter {
  estados: OptionType[];
  cidades: OptionType[];
  bairros: OptionType[];
  filtro: OptionType[];
}

const FilterSelect = ({ onSearchChange }: { onSearchChange: (ObjectFilter: any) => void }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [ObjectFilter, setStateFilter] = useState<StateFilter>({
    estados: [],
    cidades: [],
    bairros: [],
    filtro: []
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length >= 2) {
        axios.get(`https://locare-api.azurewebsites.net/api/site/localidades?term=${inputValue}&empresaId=025c1450-3ab4-4e5d-8e3c-d69917235546`)
          .then(response => {
            const loadedOptions: OptionType[] = response.data.map((group: { label: string, options: OptionType[]; }) => {
              return group.options.map(option => ({
                label: option.label,
                id: option.id,
                type: group.label.toLowerCase()  // Adiciona tipo com base no label do grupo
              }));
            }).flat();
            const userInputOption: OptionType = { label: inputValue, id: `user-input-${inputValue}`, type: 'filtro' };
            setOptions([userInputOption, ...loadedOptions]);
          })
          .catch(error => console.error('Error fetching data:', error));
      } else {
        const userInputOption: OptionType = { label: inputValue, id: `user-input-${inputValue}`, type: 'filtro' };
        setOptions([userInputOption]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleSelectChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionType[]) => {
    setSelectedOptions(newValue);

    const newFilter: StateFilter = {
      estados: [],
      cidades: [],
      bairros: [],
      filtro: []
    };

    newValue.forEach((option:any) => {
      if (option.type === 'estados') {
        newFilter.estados.push(option);
      } else if (option.type === 'cidades') {
        newFilter.cidades.push(option);
      } else if (option.type === 'bairros') {
        newFilter.bairros.push(option);
      } else {
        newFilter.filtro.push(option);
      }
    });

    setStateFilter(newFilter);
    onSearchChange(newFilter);
  };

  return (
    <Autocomplete
      multiple
      id="dynamic-autocomplete"
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleSelectChange}
      onInputChange={handleInputChange}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText="Nenhuma opção disponível"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selecione as localidades"
          placeholder="Digite para buscar..."
        />
      )}
    />
  );
};

export default FilterSelect;
