import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, IconButton, Box, Button, AlertColor } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAlert from '../../../../components/CustomAlert';
import ConfirmationDialog from '../../../../components/ConfirmDialog';
import CreateOfficeForm from '../Form';

const OfficesTable = () => {
    const [offices, setOffices] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedOffice, setSelectedOffice] = useState<any>(null);
    const [selectedOfficeId, setSelectedOfficeId] = useState<any>(null);
    const [alertInfo, setAlertInfo] = useState<{
        severity: AlertColor;
        message: string;
    }>({
        severity: "error",
        message: "",
    });

    useEffect(() => {
        const fetchOffices = async () => {
            try {
                const response = await api.get('list-offices');
                setOffices(response.data);
            } catch (error) {
                setAlertInfo({
                    severity: "error",
                    message: "Não foi possível carregar os escritórios, por favor tente novamente mais tarde.",
                });
                console.error('Failed to fetch offices', error);
            }
        };
        fetchOffices();
    }, []);

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEdit = (office: any) => {
        setSelectedOffice(office);
        setOpenCreateDialog(true);
    };

    const handleDelete = (id: number) => {
        setSelectedOfficeId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedOfficeId(null);
    };

    const handleConfirmDelete = async () => {
        if (selectedOfficeId !== null) {
            try {
                await api.delete(`delete-office/${selectedOfficeId}`);
                setOffices(offices.filter((office: any) => office.id !== selectedOfficeId));
                setAlertInfo({
                    severity: "success",
                    message: "Escritório deletado com sucesso!",
                });
                handleCloseDialog();
            } catch (error) {
                setAlertInfo({
                    severity: "error",
                    message: "Não foi possível deletar o escritório, por favor tente novamente mais tarde.",
                });
                console.error('Failed to delete office', error);
            }
        }
    };

    const handleAlertClose = () => {
        setAlertInfo({
            severity: "error",
            message: "",
        });
    };

    const handleOpenCreateDialog = () => {
        setSelectedOffice(null); // Reset selected office when opening for creation
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleCreateOrUpdateOffice = async (formData: FormData) => {
        try {
            if (selectedOffice) {
                // Update office
                await api.put(`update-office/${selectedOffice.id}`, formData);
                setAlertInfo({
                    severity: "success",
                    message: "Escritório atualizado com sucesso!",
                });
            } else {
                // Create new office
                await api.post('create-office', formData);
                setAlertInfo({
                    severity: "success",
                    message: "Escritório criado com sucesso!",
                });
            }
            const response = await api.get('list-offices');
            setOffices(response.data);
        } catch (error) {
            setAlertInfo({
                severity: "error",
                message: "Não foi possível criar/atualizar o escritório, por favor tente novamente mais tarde.",
            });
            console.error('Failed to create/update office', error);
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Button variant="contained" color="primary" onClick={handleOpenCreateDialog} sx={{ margin: 2 }}>
                Criar Novo Escritório
            </Button>
            <TableContainer sx={{ maxHeight: 'calc(100svh - 166px)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Localização</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((office: any) => (
                            <TableRow key={office.id}>
                                <TableCell>{office.id}</TableCell>
                                <TableCell>{office.name}</TableCell>
                                <TableCell>{office.location}</TableCell>
                                <TableCell>
                                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                                        <IconButton onClick={() => handleEdit(office)} color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(office.id)} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={offices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                title="Confirmação de Deleção"
                message="Tem certeza de que deseja deletar este escritório?"
            />
            <CreateOfficeForm
                open={openCreateDialog}
                onClose={handleCloseCreateDialog}
                onCreate={handleCreateOrUpdateOffice}
                office={selectedOffice}
            />
            {alertInfo.message && (
                <CustomAlert
                    severity={alertInfo.severity}
                    message={alertInfo.message}
                    onClose={handleAlertClose}
                />
            )}
        </Paper>
    );
};

export default OfficesTable;
