import React, { useState } from 'react';
import './style.css'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box'; // Para espaçamento flexível
import { useNavigate } from 'react-router-dom';


const Header = ({ handleDrawerToggle }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate(); // Chame o useNavigate aqui, no topo do componente

  const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Exemplo de notificações com datas
  const notifications = [
    { id: 1, title: "Notificação 1", details: "Detalhes da notificação 1", date: "10/jan" },
    { id: 2, title: "Notificação 2", details: "Detalhes da notificação 2", date: "11/jan" },
    { id: 3, title: "Notificação 3", details: "Detalhes da notificação 3", date: "12/jan" },
    { id: 4, title: "Notificação 4", details: "Detalhes da notificação 4", date: "13/jan" },
    { id: 5, title: "Notificação 5", details: "Detalhes da notificação 5", date: "14/jan" },
  ];

  
  return (
    <AppBar position="fixed" sx={{ width: { sm: `calc(100% - 240px)` }, ml: { sm: `240px` } }}>
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
          <MenuIcon  style={{color: '#000'}}/>
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
       
      </Toolbar>
    </AppBar>
  );
};


export default Header;
