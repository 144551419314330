import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, IconButton, Box, Button, AlertColor } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAlert from '../../../../components/CustomAlert';
import ConfirmationDialog from '../../../../components/ConfirmDialog';
import CreatePropertyForm from '../Form';

function PropertiesTable() {
    const [properties, setProperties] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState<any>(null);
    const [selectedPropertyId, setSelectedPropertyId] = useState<any>(null);
    const [alertInfo, setAlertInfo] = useState<{
        severity: AlertColor;
        message: string;
    }>({
        severity: "error",
        message: "",
    });

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await api.get('list-properties');
                setProperties(response.data);
            } catch (error) {
                setAlertInfo({
                    severity: "error",
                    message: "Não foi possível carregar as propriedades, por favor tente novamente mais tarde.",
                });
                console.error('Failed to fetch properties', error);
            }
        };
        fetchProperties();
    }, []);

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEdit = (property: any) => {
        setSelectedProperty(property);
        setOpenCreateDialog(true);
    };

    const handleDelete = (id: number) => {
        setSelectedPropertyId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedPropertyId(null);
    };

    const handleConfirmDelete = async () => {
        if (selectedPropertyId !== null) {
            try {
                await api.delete(`delete-property/${selectedPropertyId}`);
                setProperties(properties.filter((property: any) => property.id !== selectedPropertyId));
                setAlertInfo({
                    severity: "success",
                    message: "Propriedade deletada com sucesso!",
                });
                handleCloseDialog();
            } catch (error) {
                setAlertInfo({
                    severity: "error",
                    message: "Não foi possível deletar a propriedade, por favor tente novamente mais tarde.",
                });
                console.error('Failed to delete property', error);
            }
        }
    };

    const handleAlertClose = () => {
        setAlertInfo({
            severity: "error",
            message: "",
        });
    };

    const handleOpenCreateDialog = () => {
        setSelectedProperty(null); // Reset selected property when opening for creation
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleCreateOrUpdateProperty = async (formData: FormData) => {
        try {
            if (selectedProperty) {
                // Update property
                await api.put(`update-property/${selectedProperty.id}`, formData);
                setAlertInfo({
                    severity: "success",
                    message: "Propriedade atualizada com sucesso!",
                });
            } else {
                // Create new property
                await api.post('create-property', formData);
                setAlertInfo({
                    severity: "success",
                    message: "Propriedade criada com sucesso!",
                });
            }
            const response = await api.get('list-properties');
            setProperties(response.data);
        } catch (error) {
            setAlertInfo({
                severity: "error",
                message: "Não foi possível criar/atualizar a propriedade, por favor tente novamente mais tarde.",
            });
            console.error('Failed to create/update property', error);
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Button variant="contained" color="primary" onClick={handleOpenCreateDialog} sx={{ margin: 2 }}>
                Criar Novo Imóvel
            </Button>
            <TableContainer sx={{ maxHeight: 'calc(100svh - 166px)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Capa</TableCell>
                            <TableCell>Título</TableCell>
                            <TableCell>Cidade</TableCell>
                            <TableCell>Preço de Venda</TableCell>
                            <TableCell>Preço de Aluguel</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {properties.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((property: any) => (
                            <TableRow key={property.id}>
                                <TableCell>{property.id}</TableCell>
                                <TableCell>
                                    {property.images && property.images.length > 0 ? (
                                        <img src={property.images[0].path} alt="Imagem principal" width={100} height={100} style={{ objectFit: 'cover' }} />
                                    ) : (
                                        'Sem imagem'
                                    )}
                                </TableCell>
                                <TableCell>{property.friendly_title}</TableCell>
                                <TableCell>{property.city} - {property.state_abbreviation}</TableCell>
                                <TableCell>{property.sale_price ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(property.sale_price) : ''}</TableCell>
                                <TableCell>{property.rent_price ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(property.rent_price) : ''}</TableCell>
                                <TableCell>
                                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                                        <IconButton onClick={() => handleEdit(property)} color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(property.id)} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={properties.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                title="Confirmação de Deleção"
                message="Tem certeza de que deseja deletar esta propriedade?"
            />
            <CreatePropertyForm
                open={openCreateDialog}
                onClose={handleCloseCreateDialog}
                onCreate={handleCreateOrUpdateProperty}
                property={selectedProperty}
            />
            {alertInfo.message && (
                <CustomAlert
                    severity={alertInfo.severity}
                    message={alertInfo.message}
                    onClose={handleAlertClose}
                />
            )}
        </Paper>
    );
}

export default PropertiesTable;
