import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, Box, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Alert } from '@mui/material';

const CreateOfficeForm = ({ open, onClose, onCreate, office }: any) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: office || {}
    });
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (office) {
            reset(office);
        }
    }, [office, reset]);

    const onSubmit = async (data: any) => {
        const formData = new FormData();

        // Adiciona campos do formulário ao FormData
        for (const key in data) {
            formData.append(key, data[key]);
        }

        // Chama a função onCreate com o FormData
        onCreate(formData);

        // Reseta o formulário
        reset();
        setShowAlert(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{office ? 'Editar Escritório' : 'Criar Escritório'}</DialogTitle>
            <DialogContent>
                {showAlert && <Alert severity="error">Por favor, preencha todos os campos obrigatórios.</Alert>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Nome <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.name}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Cidade <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.city}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{ required: "Este campo é obrigatório" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Telefone <span style={{ color: 'red' }}>*</span></Typography>}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.phone}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="latitude_id"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={<Typography>Id Latitude</Typography>}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="URL Google Maps"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">Cancelar</Button>
                        <Button type="submit" color="primary" variant="contained">{office ? 'Atualizar' : 'Criar'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreateOfficeForm;
