import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message }: any) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>
                    Cancelar
                </Button>
                <Button onClick={onConfirm} sx={{ color: 'white', backgroundColor: '#d32f2f', '&:hover': { backgroundColor: 'FireBrick' } }} autoFocus>
                    Confirmar
                </Button>

            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;